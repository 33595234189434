import { useState, useEffect } from 'react'
import { Logo, FormRow, Alert } from '../components'
import Wrapper from '../assets/wrappers/RegisterPage'
// global context and useNavigate later
import { useAppContext } from '../context/appContext'
import { useNavigate } from 'react-router-dom'

const initialState = {
	name: '',
	email: '',
	password: '',
	isMember: true,
}
// if possible prefer local state
// global state

function Register() {
	const [values, setValues] = useState(initialState)

	// global context and useNavigate
	const navigate = useNavigate()
	const { isLoading, showAlert, displayAlert, user, setupUser } =
		useAppContext()

	const toggleMember = () => {
		setValues({ ...values, isMember: !values.isMember })
	}
	const handleChange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value })
	}

	const onSubmit = (e) => {
		e.preventDefault()
		const { name, email, password, isMember } = values
		if (!email || !password || (!isMember && !name)) {
			displayAlert()
			return
		}
		const currentUser = { name, email, password }
		if (isMember) {
			setupUser({
				currentUser,
				endPoint: 'login',
				alertText: 'Login successful!. Redirecting soon',
			})
		} else {
			setupUser({
				currentUser,
				endPoint: 'register',
				alertText: 'Registration successful!. Redirecting soon',
			})
		}
	}

	useEffect(() => {
		if (user) {
			setTimeout(() => {
				navigate('/')
			}, 3000)
		}
	}, [user, navigate])
	return (
		<Wrapper className='full-page'>
			<form className='form' onSubmit={onSubmit}>
				<Logo />
				<h3>{values.isMember ? 'Login' : 'Register'}</h3>
				{showAlert && <Alert />}

				{/* name input */}
				{!values.isMember && (
					<FormRow
						type='text'
						name='name'
						value={values.name}
						handleChange={handleChange}
					/>
				)}

				{/* email input */}
				<FormRow
					type='email'
					name='email'
					value={values.email}
					handleChange={handleChange}
				/>
				{/* password input */}
				<FormRow
					type='password'
					name='password'
					value={values.password}
					handleChange={handleChange}
				/>

				<button
					type='submit'
					className='btn btn-block'
					disabled={isLoading}
				>
					submit
				</button>

				<p>
					{values.isMember
						? 'Not a member yet?'
						: 'Already a member?'}

					<button
						type='button'
						onClick={toggleMember}
						className='member-btn'
					>
						{values.isMember ? 'Register' : 'Login'}
					</button>
				</p>
			</form>
		</Wrapper>
	)
}

export default Register
