import { Link } from 'react-router-dom'
import img from '../assets/images/not-found.svg'
import Wrapper from '../assets/wrappers/ErrorPage'

const Error = () => {
	return (
		<Wrapper className='full-page'>
			<div style={{ maxWidth: 600, padding: 20 }}>
				<img style={{ width: '100%' }} src={img} alt='not found' />
				<h3>Ohh! page not found</h3>
				<p>We can't seem to find the page you're looking for</p>
				<Link to='/'>back home</Link>
			</div>
		</Wrapper>
	)
}

export default Error
